import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Row from "react-bootstrap/Row"
import tick from "../../images/cyberSecurity/tick.png"
import * as styles from "./WhyChoose.module.scss"

const WhyChoose = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  
  return (
    <div className={styles.hireCyber}>
      <Container>
        <Row className="gap-30 align-items-center">
          <Col
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
          >
            <div className={styles.hire}>
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
            </div>
            <div className={styles.cards}>
              {strapiData?.cards &&
                strapiData?.cards?.map((item, index) => (
                  <div className={styles.talent} key={index}>
                    <img className={styles.tick} src={tick} alt={item?.title} />
                    <div className={styles.text}>
                      <h3>{item?.title}</h3>
                    </div>
                  </div>
                ))}
            </div>
            <div className={styles.teamBtn}>
              {strapiData?.buttons[0] && (
                <Link to={strapiData?.buttons[0]?.url} className={styles.btn}>
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Col>
          <Col
            lg={{ span: 6, order: 2 }}
            md={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
          >
            <div className={styles.hire}>
              {image ? (
                <GatsbyImage
                  placeholder="blurred"
                  decoding="async"
                  loading="lazy"
                  image={image}
                  alt={strapiData?.title}
                />
              ) : (
                <img
                  src={strapiData?.secImages[0]?.localFile?.publicURL}
                  alt={"Hire The Best DevOps Engineer"}
                  decoding="async"
                  loading="lazy"
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WhyChoose
